import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { User } from '../models/user.model';
import { AuthService } from '../services/auth.service';
import { ErrorHttp } from '../models/errorHttp.model';

/** This file is divided in 3 parts: 
 * - The definition of the Store : defining data structure
 * - Constants that allow NgXs to trigger Actions
 * - Selectors & Actions : Functions allowed to interact with the store
 * 
*/


// Store definition
export interface AuthStateModel {
    loggedUser: User | null;
    error: ErrorHttp | null
}

//Constants

export class Login {
    static readonly type = 'AUTH/LOGIN';

    constructor(public payload: { email: string; password: string }) {}
}


export class Logout {
    static readonly type = 'AUTH/LOGOUT';
}

export class Register {
    static readonly type = 'AUTH/REGISTER';
    constructor(public payload: { loggedUser: User }) {}
}

//Actions 

@State<AuthStateModel>({
    name: 'auth',
    defaults: {
        loggedUser: null,
        error: null
    },
})
@Injectable()
export class AuthState {
    @Selector()
    public static loggedUser(state: AuthStateModel): User | null {
        return state.loggedUser;
    }

    @Selector()
    public static error(state: AuthStateModel): any {
        return state.error;
    }

    constructor(private authService: AuthService) {}

 
    @Action(Login)
    public async login(ctx: StateContext<AuthStateModel>, action: Login): Promise<void> {
        try{
            const user = await this.authService.login(action.payload.email, action.payload.password);
            ctx.patchState({ loggedUser: user, error: null});
        }
        catch(err: any){
            console.log(err);
            ctx.patchState({loggedUser: null, error: {statusCode: err.error?.statusCode, message: err.error?.message}});
        }
    }   
    
    @Action(Register)
    public register(ctx: StateContext<AuthStateModel>, action: Register): void {
        ctx.patchState({ loggedUser: action.payload.loggedUser, error: null});
    }
 
    @Action(Logout)
    public async logout(ctx: StateContext<AuthStateModel>): Promise<void> {
        ctx.patchState({ loggedUser: null, error: null});
        localStorage.removeItem("Authorization");
        window.location.reload();
    }
}
