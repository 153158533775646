import { Component, Inject, } from '@angular/core';
import { FormBuilder, FormGroupDirective } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertService, LayerService } from '@ed---interne/ng-uui-components';

import { FolderService } from 'src/app/core/services/folder.service';
import { emailValidator } from '../../../../../shared/validators/email.validator';

@Component({
    selector: 'transfer-email',
    templateUrl: './transfer-email.component.html',
    styleUrls: ['./transfer-email.component.scss'],
    providers: [FormGroupDirective]
})
export class TransferEmailComponent {
    public folderName: string = '';
    public reference: string = '';
    public previousEmail: string = '';
    public newEmail: string = ''
    public isEmailValid: boolean = false;
    public displayError: boolean = false;

    public set params(params: { folderName: string; reference: string; previousEmail: string; newEmail: string }) {
        this.folderName = params.folderName;
        this.reference = params.reference;
        this.previousEmail = params.previousEmail;
        this.newEmail = params.newEmail;
    }

    public formGroup = this.formBuilder.group({
        email: ['', [emailValidator()]],
    });

    constructor(
        @Inject(FolderService) private folderService: FolderService,
        private readonly alertService: AlertService,
        private readonly formBuilder: FormBuilder,
        private readonly layerService: LayerService,
        private readonly router: Router,
    ) { }

    public checkEmailValidity(): void {
        const email = this.formGroup.get('email');
        this.isEmailValid = (email?.value && email?.valid && email.value === this.newEmail) || false;
    }

    public async transferEmail(): Promise<void> {
        try {
            await this.folderService.updateEmail(this.newEmail);
            this.alertService.valid('', 'Les modifications ont été enregistrées.');
            this.router.navigate(['folder'], { queryParams: { email: this.newEmail, reference: this.reference } });
            this.folderService.setEmailValidity(false);
            this.layerService.close();
        } catch (e) {
            this.alertService.error('Un problème est survenu.', "Impossible de transférer l'adresse email.");
        }
    }

    public cancel(): void {
        this.layerService.close();
    }
}