<div class="container folder-sections">
    <div class="row g-4 px-2">
        <ng-container *ngFor="let step of stepsWithLabel; let idxStep = index;">
            <mat-accordion>
                <mat-expansion-panel [expanded]="step.id === this.folder.stepId" (opened)="isPanelOpen = true" (closed)="isPanelOpen = false">
                    <mat-expansion-panel-header>
                        <mat-panel-title class="py-3">
                            <img src="{{ step.svg }}">
                            <div class="title-panel ms-3">{{ step.name }}</div>
                        </mat-panel-title>

                        <mat-panel-description class="me-2 d-flex justify-content-end">
                            <div class="container">
                                <div class="title-conforme w-100 d-flex align-items-center justify-content-end" *ngIf="getImproperCount(step.id) == 0 && getUntreatedCount(step.id) == 0">
                                    <svg class="me-2" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.6667 3.5L5.25001 9.91667L2.33334 7" stroke="#039855" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                    Tous les points obligatoires sont conformes
                                </div>
                                <div class="row d-flex justify-content-end">
                                    <div class="title-improper col-lg-auto d-flex align-items-center justify-content-end" *ngIf="getUntreatedCount(step.id) > 0" >
                                        <svg class="me-2" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M6.99996 4.66663V6.99996M6.99996 9.33329H7.00579M12.8333 6.99996C12.8333 10.2216 10.2216 12.8333 6.99996 12.8333C3.7783 12.8333 1.16663 10.2216 1.16663 6.99996C1.16663 3.7783 3.7783 1.16663 6.99996 1.16663C10.2216 1.16663 12.8333 3.7783 12.8333 6.99996Z" stroke="#475467" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg> 
                                        <ng-container *ngIf="getUntreatedCount(step.id) == 1">
                                            {{ getUntreatedCount(step.id) }} point obligatoire non traité
                                        </ng-container>
                                        <ng-container *ngIf="getUntreatedCount(step.id) > 1">
                                            {{ getUntreatedCount(step.id) }} points obligatoires non traités
                                        </ng-container>
                                    </div>
                                    <div class="title-untreated col-lg-auto d-flex align-items-center justify-content-end" *ngIf="getImproperCount(step.id) > 0">
                                        <svg class="me-2" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.91668 4.08337L4.08334 9.91671M4.08334 4.08337L9.91668 9.91671" stroke="#D92D20" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                        <ng-container *ngIf="getImproperCount(step.id) == 1">
                                            {{ getImproperCount(step.id) }} point obligatoire non conforme
                                        </ng-container>
                                        <ng-container *ngIf="getImproperCount(step.id) > 1">
                                            {{ getImproperCount(step.id) }} points obligatoires non conformes
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <ng-container *ngFor="let folderCheckpoint of getfolderCheckpoint(step.id); let idxFCheckpoint = index;">
                        <div [hidden]="isCheckpointHidden(folderCheckpoint)" class="row g-2 folder-checkpoint">
                            <div class="col-lg-1 d-flex align-items-start badge-code">
                                <span class="badge rounded-pill d-flex align-items-center" [ngClass]="{'mandatory-on': folderCheckpoint.checkpoint.isMandatory, 'optional-on': !folderCheckpoint.checkpoint.isMandatory}">
                                    <svg *ngIf="folderCheckpoint.checkpoint.isMandatory" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.99996 4.66663V6.99996M6.99996 9.33329H7.00579M12.8333 6.99996C12.8333 10.2216 10.2216 12.8333 6.99996 12.8333C3.7783 12.8333 1.16663 10.2216 1.16663 6.99996C1.16663 3.7783 3.7783 1.16663 6.99996 1.16663C10.2216 1.16663 12.8333 3.7783 12.8333 6.99996Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                    <svg *ngIf="!folderCheckpoint.checkpoint.isMandatory" width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.33333 1.16668V3.88525C6.33333 4.01194 6.33333 4.07528 6.31403 4.12567C6.29581 4.1732 6.27171 4.20771 6.23334 4.24116C6.19266 4.27662 6.12679 4.30075 5.99504 4.34902C4.3202 4.96258 3.125 6.57086 3.125 8.45829C3.125 10.8745 5.08375 12.8333 7.5 12.8333C9.91625 12.8333 11.875 10.8745 11.875 8.45829C11.875 6.57086 10.6798 4.96258 9.00496 4.34902C8.87321 4.30075 8.80734 4.27662 8.76666 4.24116C8.72829 4.20771 8.70419 4.1732 8.68597 4.12567C8.66667 4.07528 8.66667 4.01194 8.66667 3.88525V1.16668M5.45833 1.16663H9.54167" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                    <span>
                                        {{ folderCheckpoint.checkpoint.code }}
                                    </span>
                                </span>
                            </div>
                            <div class="col-lg">
                                <div class="mb-2">
                                    <div><b>{{ folderCheckpoint.checkpoint.title }}</b></div>
                                    <div>{{ folderCheckpoint.checkpoint.detail }}</div>
                                </div>
                                <div class="container px-0">
                                    <div class="row g-2">
                                        <ng-container *ngFor="let tag of folderCheckpoint.checkpoint.tags">
                                            <div class="col-auto">
                                                <span class="badge rounded-pill bg-orfee-gray fw-bolder">{{ tag.name }}</span>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-auto">
                                <div class="row g-0">
                                    <div class="col-auto">
                                        <ed-button
                                            id="{{ 'compliant-' + idxStep + idxFCheckpoint }}"
                                            [noBorders]="true"
                                            [small]="true"
                                            [ngClass]="{'compliant-on': folderCheckpoint.state.name == 'CONFORME', 'compliant-off': !(folderCheckpoint.state.name == 'CONFORME')}"
                                            (click)="updateCompliant(folderCheckpoint.id)"
                                            placement="top"
                                            tooltipClass="tooltip-orfee"
                                            triggers="hover"
                                            ngbTooltip="Conforme">
                                            <svg width="25" height="25" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M16.6667 5L7.50001 14.1667L3.33334 10" stroke="#D92D20" stroke="white" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </ed-button>
                                    </div>
                                    <div class="col-auto">
                                        <ed-button
                                            id="{{ 'improper-' + idxStep + idxFCheckpoint }}"
                                            [noBorders]="true"
                                            [small]="true"
                                            [ngClass]="{'improper-on': folderCheckpoint.state.name == 'NON CONFORME', 'improper-off': !(folderCheckpoint.state.name == 'NON CONFORME')}"
                                            (click)="updateImproper(folderCheckpoint.id)"
                                            placement="top"
                                            tooltipClass="tooltip-orfee"
                                            triggers="hover"
                                            ngbTooltip="Non conforme">
                                            <svg width="25" height="25" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M14.1667 5.83337L5.83333 14.1667M5.83333 5.83337L14.1667 14.1667" stroke="#D92D20" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </ed-button>
                                    </div>
                                    <div class="col-auto">
                                        <ed-button
                                            id="{{ 'inapplicable-' + idxStep + idxFCheckpoint }}"
                                            [noBorders]="true"
                                            [small]="true"
                                            [ngClass]="{'inapplicable-on': folderCheckpoint.state.name == 'NON APPLICABLE', 'inapplicable-off': !(folderCheckpoint.state.name == 'NON APPLICABLE')}"
                                            (click)="updateInapplicable(folderCheckpoint.id)"
                                            placement="top"
                                            tooltipClass="tooltip-orfee"
                                            triggers="hover"
                                            ngbTooltip="Non appliquable au projet">
                                            <svg width="23" height="23" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M4.10834 4.10829L15.8917 15.8916M18.3333 9.99996C18.3333 14.6023 14.6024 18.3333 10 18.3333C5.39763 18.3333 1.66667 14.6023 1.66667 9.99996C1.66667 5.39759 5.39763 1.66663 10 1.66663C14.6024 1.66663 18.3333 5.39759 18.3333 9.99996Z" stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </ed-button>
                                    </div>
                                    <div class="col-auto ps-2">
                                        <ed-button
                                            id="{{ 'comment' + idxStep + idxFCheckpoint }}"
                                            tippyClassName="ed-custom-tippy"
                                            [className]="folderCheckpoint.comment == '' ? 'btnComment-on' : 'btnComment-off'"
                                            (click)="updateComment(folderCheckpoint)"
                                            placement="top"
                                            tooltipClass="tooltip-orfee"
                                            triggers="hover"
                                            [ngbTooltip]="folderCheckpoint.comment == '' ? 'Rédiger un commentaire' : 'Editer le commentaire'"
                                            container="body">
                                            <svg width="20" height="20" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M4.83333 6.08333H9M4.83333 9H11.5M7.06979 14H12.5C13.9001 14 14.6002 14 15.135 13.7275C15.6054 13.4878 15.9878 13.1054 16.2275 12.635C16.5 12.1002 16.5 11.4001 16.5 10V5.5C16.5 4.09987 16.5 3.3998 16.2275 2.86502C15.9878 2.39462 15.6054 2.01217 15.135 1.77248C14.6002 1.5 13.9001 1.5 12.5 1.5H5.5C4.09987 1.5 3.3998 1.5 2.86502 1.77248C2.39462 2.01217 2.01217 2.39462 1.77248 2.86502C1.5 3.3998 1.5 4.09987 1.5 5.5V15.9463C1.5 16.3903 1.5 16.6123 1.59102 16.7263C1.67019 16.8255 1.79022 16.8832 1.91712 16.8831C2.06302 16.8829 2.23639 16.7442 2.58313 16.4668L4.57101 14.8765C4.9771 14.5517 5.18014 14.3892 5.40624 14.2737C5.60683 14.1712 5.82036 14.0963 6.04101 14.051C6.28972 14 6.54975 14 7.06979 14Z" stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </ed-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </mat-expansion-panel>
            </mat-accordion>
        </ng-container>
    </div>
</div>
