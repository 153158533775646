<form [formGroup]="parametersFormGroup">
    <div class="container folder-params">
        <div class="row g-3">
            <div class="col-lg-6">
                <div class="row g-2">
                    <div class="col-sm-4">
                        <label for="folder-name" class="form-label">Nom du projet</label>
                        <!-- use bootstrap input as ed-textfield doesn't support blur event -->
                        <input [ngClass]="{valid: isNameValid, invalid: !isNameValid}"
                            #nameInput
                            placeholder="Saisissez le nom du projet"
                            type="text" 
                            class="form-control" 
                            id="folder-name" 
                            formControlName="name"
                            (blur)="updateName()"
                            (ngModelChange)="checkNameValidity()"
                            (keydown.enter)="updateName()">
                        <!-- <ed-textfield
                            id="folder-name"
                            type="text"
                            (focus)="onFocus()"
                            (blur)="onBlur()"
                            (ngModelChange)="checkNameValidity()"
                            [displayError]="displayError"
                            placeholder="Saisissez le nom du projet"
                            formControlName="name">
                        </ed-textfield> -->
                    </div>
                    <div class="col-sm-4">
                        <label for="folder-step" class="form-label">Etape du projet</label>
                        <select class="form-select" id="folder-step" formControlName="step" (change)="updateStep()">
                            <ng-container *ngFor="let step of steps">
                                <option [value]="step.id">
                                    {{ step.name }}
                                </option>
                            </ng-container>
                        </select>
                    </div>
                    <div class="col-sm-4">
                        <label for="folder-reference" class="form-label">Référence</label>
                        <div class="d-flex">
                            <input type="text" class="form-control" id="folder-reference" formControlName="reference">
                            <ed-button class="btn-red ms-2" (click)="showDeleteFolderModal()" id="folder-delete" title="Supprimer le dossier">
                                <img src="assets/svg/bin.svg" alt="Bin icon">
                            </ed-button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="row g-2">
                    <div class="col-sm">
                        <label for="folder-email" class="form-label">Adresse email associée</label>
                        <form [formGroup]="parametersFormGroup" class="d-flex">
                            <ed-textfield
                                id="folder-email"
                                type="email"
                                (ngModelChange)="checkEmailValidity()"
                                [displayError]="displayError"
                                icon="icon-mail-01"
                                placeholder="Saisissez l'adresse email de transfert"
                                formControlName="email">
                                </ed-textfield>
                            <ed-button class="btn-red ms-2" (click)="showTransferEmailModal()" [disabled]="!this.folderService.isEmailValid" title="Transférer le dossier">
                                <img src="assets/svg/user_change.svg" alt="user-change-icon">
                            </ed-button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
