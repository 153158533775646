<ed-alert></ed-alert>

<div class="page">
    <app-header></app-header>

    <div class="container main-content text-home py-4   ">
        <div class="row justify-content-center">
            <div class="col-lg-8 text-center">
                <p>
                    Cet outil est mis à disposition des sociétés de rénovation membres du réseau SERAFIN, afin de les aider dans le contrôle du cadre de conformité des projets. 
                    Il s’applique à toutes les étapes, du diagnostic énergétique, conception du projet, sélection des entreprises, au suivi du chantier et post travaux.
                </p>
                <p>
                    Les dossiers sont accessibles via&nbsp;<b>une adresse email</b>, et un code référence au format&nbsp;<b>XXXXXX</b>.
                </p>                
            </div>
        </div>

        <div class="row mb-5 d-flex justify-content-center">
            <div class="col-lg-5">
                <form [formGroup]="loginFormGroup">
                    <ed-textfield
                        id="email"
                        [type]="'email'"
                        (ngModelChange)="checkMailValidity()"
                        [displayError]="displayError"
                        [icon]="'icon-mail-01'"
                        [placeholder]="'Saisissez votre adresse email'"
                        [formControlName]="'email'">
                    </ed-textfield>
                    <small class="alert text-orfee-red" *ngIf="loginFormGroup.get('email')?.hasError('email')">
                        Le format de l'adresse email est incorrect.
                    </small>
                </form>
            </div>
        </div>

        <ng-container *ngIf="checkMailValidity()">
            <div class="row mb-2">
                <div class="col-lg-12 text-center">
                    Que souhaitez-vous faire aujourd’hui ?
                </div>
            </div>
    
            <div class="row justify-content-center g-2 mb-2" [formGroup]="createFormGroup">
                <div class="col-lg-2">
                    <select class="form-select" formControlName="houseTypeId">
                        <ng-container *ngFor="let option of houseTypeOptions">
                            <option [value]="option.value">
                                {{ option.label }}
                            </option>
                        </ng-container>
                    </select>
                </div>
                <div class="col-lg-4">
                    <ed-textfield
                        id="name"
                        [displayError]="displayError"
                        [formControlName]="'name'"
                        [placeholder]="'Donnez un nom à votre dossier'">
                    </ed-textfield>
                </div>
                <div class="col-lg-2">
                    <ed-button 
                        class="btn-red btn-home"
                        (click)="createFolder()"
                        [disabled]="disableCreateFolder()">
                        <ng-container *ngIf="!createFolderProgress">Créer un dossier</ng-container>
                        <ng-container *ngIf="createFolderProgress">
                            <ed-spinner [spinning]="true"></ed-spinner>
                        </ng-container>
                    </ed-button>
                </div>
            </div>

            <div class="row justify-content-center g-2 mb-5">
                <div class="col-lg-6" [formGroup]="referenceFormGroup">
                    <ed-textfield
                        id="reference"
                        formControlName="reference"
                        placeholder="Saisissez une référence existante (ex: FGUHZJUS)">
                    </ed-textfield>
                </div>
                <div class="col-lg-2">
                    <ed-button class="btn-red btn-home" (click)="openFolder()" [disabled]="disableOpenFolder()" >
                        Accéder au dossier
                    </ed-button>
                </div>
            </div>

            <div class="row justify-content-center">
                <div class="col-lg-8 text-center">
                    <div class="mb-2">
                        Vous pouvez aussi recevoir un récapitulatif de vos dossiers par mail.
                    </div>
                    <div>
                        <ed-button class="btnSecondary custom-ed-button" (click)="sendFoldersLinkByEmail()">
                            Recevoir le détail de mes dossiers par mail
                        </ed-button>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>

