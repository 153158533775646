<div class="container folder-filters">
    <div class="row g-4">
        <div class="col-lg-auto">
            <div class="form-label">
                Filtre sur le niveau de contrôle
            </div>
            <div class="row g-2">
                <div class="col-auto">
                    <ed-button [noBorders]="true" [small]="true" [ngClass]="{'mandatory-on': folderService.mandatory.active, 'mandatory-off': !folderService.mandatory.active}" (click)="toggleMandatory()">
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.99996 4.66663V6.99996M6.99996 9.33329H7.00579M12.8333 6.99996C12.8333 10.2216 10.2216 12.8333 6.99996 12.8333C3.7783 12.8333 1.16663 10.2216 1.16663 6.99996C1.16663 3.7783 3.7783 1.16663 6.99996 1.16663C10.2216 1.16663 12.8333 3.7783 12.8333 6.99996Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>                                
                        <span>Obligatoire ({{ folderService.mandatory.total }})</span>
                    </ed-button>
                </div>
                <div class="col-auto">
                    <ed-button [noBorders]="true" [small]="true" [ngClass]="{'optional-on': folderService.optional.active, 'optional-off': !folderService.optional.active}" (click)="toggleOptional()">
                        <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.83333 1.16668V3.88525C4.83333 4.01194 4.83333 4.07528 4.81403 4.12567C4.79581 4.1732 4.77171 4.20771 4.73334 4.24116C4.69266 4.27662 4.62679 4.30075 4.49504 4.34902C2.8202 4.96258 1.625 6.57086 1.625 8.45829C1.625 10.8745 3.58375 12.8333 6 12.8333C8.41625 12.8333 10.375 10.8745 10.375 8.45829C10.375 6.57086 9.1798 4.96258 7.50496 4.34902C7.37321 4.30075 7.30734 4.27662 7.26666 4.24116C7.22829 4.20771 7.20419 4.1732 7.18597 4.12567C7.16667 4.07528 7.16667 4.01194 7.16667 3.88525V1.16668M3.95833 1.16663H8.04167" stroke="#DC6803" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <span>Contrôle échantilloné ({{ folderService.optional.total }})</span>
                    </ed-button>
                </div>
            </div>
        </div>
        <div class="col-lg-auto">
            <div class="form-label">
                Filtre sur l'état des points de contrôle
            </div>
            <div class="row g-2">
                <div class="col-auto">
                    <ed-button [noBorders]="true" [small]="true" [ngClass]="{'untreated-on': folderService.untreated.active, 'untreated-off': !folderService.untreated.active}" (click)="toggleUntreated()">
                        <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.59485 1.16663H1.40519C1.1354 1.16663 0.916687 1.38534 0.916687 1.65513C0.916687 3.20986 1.5343 4.7009 2.63365 5.80026L3.34485 6.51145C3.41547 6.58208 3.45078 6.61739 3.47606 6.6513C3.63028 6.85816 3.63028 7.14176 3.47606 7.34862C3.45078 7.38253 3.41547 7.41784 3.34485 7.48847L2.63365 8.19966C1.5343 9.29901 0.916687 10.7901 0.916687 12.3448C0.916687 12.6146 1.1354 12.8333 1.40519 12.8333H8.59485C8.86464 12.8333 9.08335 12.6146 9.08335 12.3448C9.08335 10.7901 8.46574 9.29901 7.36639 8.19966L6.65519 7.48847C6.58457 7.41784 6.54926 7.38253 6.52398 7.34862C6.36976 7.14176 6.36976 6.85816 6.52398 6.6513C6.54926 6.61739 6.58457 6.58208 6.65519 6.51145L7.36639 5.80026C8.46574 4.7009 9.08335 3.20986 9.08335 1.65513C9.08335 1.38534 8.86464 1.16663 8.59485 1.16663Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <span>Non traité ({{ folderService.untreated.total }})</span>
                    </ed-button>
                </div>
                <div class="col-auto">
                    <ed-button [noBorders]="true" [small]="true" [ngClass]="{'compliant-on': folderService.compliant.active, 'compliant-off': !folderService.compliant.active}" (click)="toggleCompliant()">
                        <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.6666 1.5L4.24998 7.91667L1.33331 5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <span>Conforme ({{ folderService.compliant.total }})</span>
                    </ed-button>
                </div>
                <div class="col-auto">
                    <ed-button [noBorders]="true" [small]="true" [ngClass]="{'improper-on': folderService.improper.active, 'improper-off': !folderService.improper.active}" (click)="toggleImproper()">
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.91665 4.08337L4.08331 9.91671M4.08331 4.08337L9.91665 9.91671" stroke="#D92D20" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <span>Non conforme ({{ folderService.improper.total }})</span>
                    </ed-button>
                </div>
                <div class="col-auto">
                    <ed-button [noBorders]="true" [small]="true" [ngClass]="{'inapplicable-on': folderService.inapplicable.active, 'inapplicable-off': !folderService.inapplicable.active}" (click)="toggleInapplicable()">
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_18177_371)">
                                <path d="M2.87582 2.87579L11.1242 11.1241M12.8333 6.99996C12.8333 10.2216 10.2217 12.8333 6.99999 12.8333C3.77833 12.8333 1.16666 10.2216 1.16666 6.99996C1.16666 3.7783 3.77833 1.16663 6.99999 1.16663C10.2217 1.16663 12.8333 3.7783 12.8333 6.99996Z" stroke="#344054" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_18177_371">
                                    <rect width="14" height="14" fill="white"/>
                                </clipPath>
                            </defs>
                        </svg>
                        <span>Non applicable ({{ folderService.inapplicable.total }})</span>
                    </ed-button>
                </div>
            </div>
        </div>
    </div>
</div>