import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
// import { HeaderComponent } from './components/header/header.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxsModule } from '@ngxs/store';
import { SharedModule } from '../shared/shared.module';
import { ToastComponent } from './components/toast/toast.component';
import { ToasterComponent } from './components/toaster/toaster.component';
import { AuthService } from './services/auth.service';
import { JwtInterceptor } from './services/jwt.interceptor';
import { ToastService } from './services/toast.service';

@NgModule({
    declarations: [
        // HeaderComponent,
        ToastComponent,
        ToasterComponent
    ],
    imports: [CommonModule, HttpClientModule, NgbModule, SharedModule, NgxsModule],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        AuthService,
        ToastService,
    ],
    exports: [
        // HeaderComponent, 
        ToastComponent,
        ToasterComponent
    ],
})
export class CoreModule { }
