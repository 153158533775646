<div class="modal-header justify-content-center mb-4">
    <h5 class="modal-title text-orfee-red fw-bold">Supprimer le dossier</h5>
</div>

<div class="modal-body justify-content-center mb-4">
    <p class="text-center">
        Confirmez-vous la suppression du dossier
        <strong>{{ folder.name }}</strong>
        dont la référence est
        <strong>{{ folder.reference }}</strong>
        ?
    </p>
    <p class="text-center">
        La suppression de ce dossier est irréversible. Il ne pourra être restauré.
    </p>
</div>

<div class="container pt-2">
    <div class="row g-2 d-flex justify-content-center">
        <div class="col-sm-4">
            <ed-button class="btn-back" (click)="cancel()">
                Retour
            </ed-button>
        </div>
        <div class="col-sm-4">
            <ed-button class="btn-red" (click)="deleteFolder()">
                Supprimer le dossier
            </ed-button>
        </div>
    </div>
</div>

