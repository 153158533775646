import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
    projectName = 'Contrôle qualité de projets de rénovation énergétique des logements';

    constructor(private router: Router, private store: Store, private modalService: NgbModal) { }

    ngOnInit() {
    }


}
