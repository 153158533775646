import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroupDirective, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertService, LayerService, LayerSize } from '@ed---interne/ng-uui-components';
import { Folder, Step } from 'src/app/core/models/folder.model';
import { FolderService } from 'src/app/core/services/folder.service';
import { StepService } from 'src/app/core/services/step.service';
import { emailValidator } from '../../../../../shared/validators/email.validator';
import { DeleteFolderComponent } from '../delete-folder/delete-folder.component';
import { TransferEmailComponent } from '../transfer-email/transfer-email.component';


@Component({
    selector: 'folder-parameters',
    templateUrl: './parameters.component.html',
    styleUrls: ['./parameters.component.scss'],
    providers: [FormGroupDirective]
})
export class ParametersComponent implements OnInit {
    @Input() folder: Folder | any;
    steps: Step[] = [];

    private previousName: string = '';
    public displayError: boolean = false;
    public isNameValid: boolean = true;

    public parametersFormGroup = this.formBuilder.group({
        name: ['', Validators.required],
        step: ['', Validators.required],
        reference: ['', Validators.required],
        email: ['', [emailValidator()]],
    });

    constructor(
        @Inject(FolderService) public folderService: FolderService,
        @Inject(StepService) private stepService: StepService,
        private readonly router: Router,
        private readonly alertService: AlertService,
        private readonly layerService: LayerService,
        private readonly formBuilder: FormBuilder,
    ) { }

    async ngOnInit(): Promise<void> {
        try {
            this.previousName = this.folder.name;
            this.folderService.setFolder(this.folder);

            this.parametersFormGroup.controls.name.setValue(this.folder.name);
            this.parametersFormGroup.controls.step.setValue(this.folder.step.id);
            this.parametersFormGroup.controls.reference.setValue(this.folder.reference);
            this.parametersFormGroup.controls.email.setValue(this.folder.email);
            this.checkEmailValidity();

            const referenceCtrl = this.parametersFormGroup.get('reference');
            referenceCtrl?.disable();

            const steps = await this.stepService.getAvailableSteps();
            this.steps = steps;
        } catch (e) {
            this.router.navigate(['/']);
            this.alertService.error('', 'Le numéro de dossier n\'existe pas ou n\'est pas associé à cette adresse mail.');
        }
    }

    public checkNameValidity() {
        const nameCtrl = this.parametersFormGroup.get('name');
        this.isNameValid = !!nameCtrl?.value && nameCtrl?.valid;
        return this.isNameValid
    }

    async updateName(): Promise<void> {
        const nameCtrl = this.parametersFormGroup.get('name');
        const name = nameCtrl?.value?.trim() || '';

        if (this.isNameValid && name != this.previousName) {
            this.folder.name = name;
            this.previousName = name;

            try {
                const data = { name: name }
                await this.folderService.updateParams(data);
                this.alertService.valid('', 'Les modifications ont été enregistrées.');
            } catch (e) {
                this.alertService.error('', 'Le numéro de dossier n\'existe pas ou n\'est pas associé à cette adresse mail.');
            }
        }
    }

    async updateStep(): Promise<void> {
        try {
            const stepCtrl = this.parametersFormGroup.get('step');
            const stepId = stepCtrl?.value || undefined;

            const data = { stepId: stepId }
            await this.folderService.updateParams(data);
            this.folder.stepId = stepId;
            this.alertService.valid('', 'Les modifications ont été enregistrées.');
        } catch (e) {
            this.alertService.error('', 'Le numéro de dossier n\'existe pas ou n\'est pas associé à cette adresse mail.');
        }
    }

    public showDeleteFolderModal(): void {
        this.layerService.show(
            DeleteFolderComponent,
            {
                folder: this.folder
            },
            {
                size: LayerSize.Medium,
            },
        );
    }

    public checkEmailValidity(): void {
        const email = this.parametersFormGroup.get('email');
        this.folderService.isEmailValid = (email?.value && email?.valid && email.value !== this.folder.email) || false;
    }

    public showTransferEmailModal(): void {
        const email = this.parametersFormGroup.get('email');
        this.layerService.show(
            TransferEmailComponent,
            {
                folderName: this.folder.name,
                reference: this.folder.reference,
                previousEmail: this.folder.email,
                newEmail: email?.value
            },
            {
                size: LayerSize.Medium,
            },
        );
    }
}
