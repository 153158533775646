import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroupDirective } from '@angular/forms';
import { AlertService, LayerService } from '@ed---interne/ng-uui-components';

import { FolderCheckpoint } from 'src/app/core/models/folder.model';
import { FolderCheckpointService } from 'src/app/core/services/folderCheckpoint.service';

@Component({
    selector: 'checkpoint-comment',
    templateUrl: './checkpoint-comment.component.html',
    styleUrls: ['./checkpoint-comment.component.scss'],
    providers: [FormGroupDirective]
})
export class CheckpointCommentComponent implements OnInit {
    public folderName: string = '';
    private folderCheckpoint: FolderCheckpoint | any = {};
    public code: string = '';
    public state: string = '';

    public set params(params: { folderName: string; folderCheckpoint: FolderCheckpoint; }) {
        this.folderName = params.folderName;
        this.folderCheckpoint = params.folderCheckpoint;
    }

    public formGroup = this.formBuilder.group({
        comment: '',
    });

    constructor(
        @Inject(FolderCheckpointService) private folderCheckpointService: FolderCheckpointService,
        private readonly alertService: AlertService,
        private readonly formBuilder: FormBuilder,
        private readonly layerService: LayerService
    ) { }

    ngOnInit(): void {
        this.code = this.folderCheckpoint.checkpoint.code;
        this.state = this.folderCheckpoint.state.name;
        this.formGroup.setValue({ comment: this.folderCheckpoint.comment });
    }

    public checkCommentValidity(): boolean {
        const comment = this.formGroup.get('comment');
        const isCommentValid = (!!comment?.value) || false;
        return isCommentValid
    }

    public async saveComment(): Promise<void> {
        try {
            const comment = this.formGroup.get('comment');
            this.folderCheckpoint.comment = comment ? comment.value : this.folderCheckpoint.comment;
            await this.folderCheckpointService.updateFolderCheckpointComment(this.folderCheckpoint);
            this.alertService.valid('', 'Les modifications ont été enregistrées.');
            this.layerService.close();
        } catch (e) {
            this.alertService.error('', 'Le point de contrôle n\'existe pas.');
        }
    }

    public cancel(): void {
        this.layerService.close();
    }
}