<div class="modal-header justify-content-center mb-4">
    <h5 class="modal-title text-orfee-red fw-bold">Transférer le dossier</h5>
</div>

<div class="modal-body justify-content-center mb-4">
    <p class="text-center">
        Confirmez-vous le transfert de propriété du dossier
        <strong>{{ folderName }}</strong>
        dont la référence est
        <strong>{{ reference }}</strong>
        ?
    </p>
    <p class="text-center">
        Le dossier ne sera plus accessible avec votre adresse mail {{ previousEmail }}.
        <br>
        Pour ce faire, veuillez confirmer la nouvelle adresse de référence pour ce projet.
    </p>
    <form [formGroup]="formGroup">
        <div class="row d-flex justify-content-center">
            <div class="col-md-7 d-flex justify-content-center">
                <ed-textfield
                    id="folder-email"
                    type="email"
                    (ngModelChange)="checkEmailValidity()"
                    [displayError]="displayError"
                    icon="icon-mail-01"
                    placeholder="Confirmer l'adresse email de transfert"
                    formControlName="email">
            </ed-textfield>
            </div>
        </div>
    </form>
</div>

<div class="container pt-2">
    <div class="row g-2 d-flex justify-content-center">
        <div class="col-sm-4">
            <ed-button class="btn-back" (click)="cancel()">
                Retour
            </ed-button>
        </div>
        <div class="col-sm-4">
            <ed-button class="btn-red" (click)="transferEmail()" [disabled]="!isEmailValid">
                Transférer le dossier
            </ed-button>
        </div>
    </div>
</div>

