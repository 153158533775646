<div class="page main-container d-flex flex-column" *ngIf="!!folder">
    
    <folder-header></folder-header>
    <div class="folder-body flex-grow-1">
        <div class="mb-4">
            <folder-parameters [folder]="folder"></folder-parameters>
        </div>
        <div class="mb-4">
            <folder-filters [folder]="folder"></folder-filters>
        </div>
        <div class="mb-4">
            <folder-sections [folder]="folder"></folder-sections>
        </div>
    </div>

</div>

<ed-alert></ed-alert>
<ed-layer-container></ed-layer-container>
