import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { MatSelectModule } from '@angular/material/select';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
    AlertModule,
    ButtonModule,
    LayerModule,
    SpinnerModule,
    TextfieldModule,
} from '@ed---interne/ng-uui-components';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxsModule } from '@ngxs/store';
import { provideEnvironmentNgxMask } from 'ngx-mask';

import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular-ivy';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { initializeAppFactory } from './app.initializer';
import { CoreModule } from './core/core.module';
import { AuthService } from './core/services/auth.service';
import { AuthState } from './core/states/auth.state';
import { AdminUsersState } from './main/states/adminUsers.state';
import { InfiniteScrollDirective } from './shared/directives/infinite-scroll.directive';
import { SharedModule } from './shared/shared.module';

@NgModule({
    declarations: [AppComponent],
    imports: [
        FormsModule,
        BrowserModule,
        AppRoutingModule,
        NgbModule,
        CoreModule,
        SharedModule,
        TextfieldModule,
        AlertModule,
        ButtonModule,
        LayerModule,
        SpinnerModule,
        BrowserAnimationsModule,
        InfiniteScrollDirective,
        MatSelectModule,
        NgxsModule.forRoot([AuthState, AdminUsersState]),
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: initializeAppFactory,
            deps: [AuthService, Sentry.TraceService],
            multi: true,
        },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: true,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        provideEnvironmentNgxMask(),
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
