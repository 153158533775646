import { Inject, Injectable } from '@angular/core';

import { Folder, FolderCheckpoint } from '../models/folder.model';
import { RestService } from './rest.service';

@Injectable({
    providedIn: 'root',
})
export class FolderService {
    public folder: Folder | any;
    public isEmailValid: boolean = false;

    public mandatory = {
        active: false,
        total: 0,
    };
    public optional = {
        active: false,
        total: 0,
    };
    public untreated = {
        active: false,
        total: 0,
    };
    public compliant = {
        active: false,
        total: 0,
    };
    public improper = {
        active: false,
        total: 0,
    };
    public inapplicable = {
        active: false,
        total: 0,
    };

    constructor(@Inject(RestService) private restService: RestService) {}

    public create(folder: Partial<Folder>): Promise<Folder> {
        const data = {
            name: folder.name,
            email: folder.email,
            typeId: folder.houseTypeId,
        };
        return this.restService.post('/folder', {}, data);
    }

    public findByReferenceEmail(reference: string, email: string): Promise<Folder> {
        return this.restService.get(`/folder/findByReferenceEmail/${reference}/${email}`, {});
    }

    public sendFoldersLinkByEmail(email: string): Promise<any> {
        return this.restService.get(`/folders/${email}/link`, {});
    }

    public updateParams(data: Partial<Folder>): Promise<Folder> {
        const url = `/folder/${this.folder.id}`;
        const body = {
            reference: this.folder.reference,
            ...data,
        };
        return this.restService.patch(url, {}, body);
    }

    public async updateEmail(newEmail: string): Promise<void> {
        const url = `/folder/${this.folder.id}/email`;
        const body = {
            reference: this.folder.reference,
            email: this.folder.email,
            newEmail: newEmail,
        };
        const updatedFolder = await this.restService.patch(url, {}, body);
        this.folder.email = updatedFolder.email;
    }

    public delete(): Promise<any> {
        const url = `/folder/${this.folder.id}/${this.folder.reference}/${this.folder.email}`;
        return this.restService.delete(url);
    }

    public setFolder(folder: Folder): void {
        this.folder = folder;
    }

    public setUntreatedCount(): void {
        this.untreated.total = this.setTotal('NON TRAITE');
    }

    public setCompliantCount(): void {
        this.compliant.total = this.setTotal('CONFORME');
    }

    public setImproperCount(): void {
        this.improper.total = this.setTotal('NON CONFORME');
    }

    public setInapplicableCount(): void {
        this.inapplicable.total = this.setTotal('NON APPLICABLE');
    }

    private setTotal(stateName: string): number {
        const mandatory = this.getMandatoryByState(stateName);
        const optional = this.getOptionalByState(stateName);

        const total =
            (this.mandatory.active ? mandatory.length : 0) +
            (this.optional.active ? optional.length : 0);
        return total;
    }

    private getMandatoryByState(stateName: string): FolderCheckpoint[] {
        const folderCheckpoints = this.folder.folderCheckpoints
            .filter((folderCheckpoint: FolderCheckpoint) => folderCheckpoint.checkpoint.isMandatory)
            .filter(
                (folderCheckpoint: FolderCheckpoint) => folderCheckpoint.state.name == stateName,
            );

        return folderCheckpoints;
    }

    private getOptionalByState(stateName: string): FolderCheckpoint[] {
        const folderCheckpoints = this.folder.folderCheckpoints
            .filter(
                (folderCheckpoint: FolderCheckpoint) => !folderCheckpoint.checkpoint.isMandatory,
            )
            .filter(
                (folderCheckpoint: FolderCheckpoint) => folderCheckpoint.state.name == stateName,
            );

        return folderCheckpoints;
    }

    public setEmailValidity(isEmailValid: boolean): void {
        this.isEmailValid = isEmailValid;
    }
}
