import { inject } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { CanActivateFn, Router, UrlTree } from '@angular/router';

export const authGuard: CanActivateFn = (): boolean | UrlTree => {
    const authService = inject(AuthService);
    const router = inject(Router);
  
    if (authService.isAuth) {
      return true;
    }
  
    return router.parseUrl('/');
  };
  export const adminAuthGuard: CanActivateFn = (): boolean | UrlTree => {
    const authService = inject(AuthService);
    const router = inject(Router);
  
    if (authService.isAuth && authService.isAdmin) {
      return true;
    }
    else if(authService.isAuth){
        return router.parseUrl('/main');
    }
  
    return router.parseUrl('/');
  };

export const notAuthGuard: CanActivateFn = (): boolean | UrlTree => {
  const authService = inject(AuthService);
  const router = inject(Router);

  if (!authService.isAuth) {
    return true;
  }

  return router.parseUrl('/main');
};
