import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

import { TextfieldModule } from '@ed---interne/ng-uui-components';
import { HeaderComponent } from './../core/components/header/header.component';

// import { ComponentsModule } from '@ed---interne/ng-uui-components';


@NgModule({
  declarations: [HeaderComponent],
  imports: [CommonModule, FormsModule, NgbDatepickerModule, NgbDropdownModule, TextfieldModule,],
  exports: [HeaderComponent],
  providers: [],
})
export class SharedModule { }
