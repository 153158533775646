import { Component, Inject, Input } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { AlertService, LayerService, LayerSize } from '@ed---interne/ng-uui-components';
import { Folder, FolderCheckpoint } from 'src/app/core/models/folder.model';
import { FolderService } from 'src/app/core/services/folder.service';
import { FolderCheckpointService } from 'src/app/core/services/folderCheckpoint.service';
import { CheckpointCommentComponent } from '../checkpoint-comment/checkpoint-comment.component';


@Component({
    selector: 'folder-sections',
    templateUrl: './sections.component.html',
    styleUrls: ['./sections.component.scss'],
    providers: [FormGroupDirective],
})
export class SectionsComponent {
    @Input() folder: Folder | any;
    public isPanelOpen = false;

    public stepsWithLabel = [
        { id: "d1f9ee72-932a-4c71-a062-3dae788552a9", name: "Diagnostic du logement", svg: "assets/svg/diagnostic.svg" },
        { id: "ec09a751-7af5-479a-b567-27fe3a34a723", name: "Conception du projet de rénovation", svg: "assets/svg/conception.svg" },
        { id: "f645e2f4-e974-430a-9a76-c146a735ed0a", name: "Consultation des entreprises", svg: "assets/svg/consultation.svg" },
        { id: "7b42401a-e95e-4b74-9378-03be4e240a05", name: "Suivi des travaux ", svg: "assets/svg/suivi.svg" },
        { id: "50cfa226-801a-49da-bb18-e6b7f96b8f82", name: "Réception du chantier", svg: "assets/svg/reception.svg" },
        { id: "d844e0a5-db33-4001-9b8e-535f9b032e38", name: "Suivi et exploitation", svg: "assets/svg/exploitation.svg" }
    ]

    constructor(
        @Inject(FolderService) private folderService: FolderService,
        @Inject(FolderCheckpointService) private folderCheckpointService: FolderCheckpointService,
        private readonly alertService: AlertService,
        private readonly layerService: LayerService,
    ) { }

    async updateCompliant(folderCheckpointId: string) {
        const newStep = { id: "5fc8713d-e379-4fd1-914d-14f7d88f8510", name: "CONFORME" };
        await this.updateState(folderCheckpointId, newStep);
    }

    async updateImproper(folderCheckpointId: string) {
        const newStep = { id: "a8ff3207-f936-4f5f-b3ff-28ab092fd908", name: "NON CONFORME" };
        await this.updateState(folderCheckpointId, newStep);
    }

    async updateInapplicable(folderCheckpointId: string) {
        const newStep = { id: "b2a2b58f-3445-4afe-8691-d599811bdf93", name: "NON APPLICABLE" };
        await this.updateState(folderCheckpointId, newStep);
    }

    private async updateState(folderCheckpointId: string, newStep: { id: string; name: string }) {
        try {
            const folderCheckpoint = this.folder.folderCheckpoints
                .find((folderCheckpoint: FolderCheckpoint) => folderCheckpoint.id == folderCheckpointId);
            folderCheckpoint.state = newStep;

            await this.folderCheckpointService.updateFolderCheckpointState(folderCheckpoint);
            this.updateStateCount();
            this.alertService.valid('', 'Les modifications ont été enregistrées.');
        } catch (e) {
            this.alertService.error('', 'Le point de contrôle n\'existe pas.');
        }
    }

    async updateComment(folderCheckpoint: FolderCheckpoint) {
        this.showCommentSectionModal(folderCheckpoint);
    }

    public getUntreatedCount(stepId: string): number {
        const untreatedCheckpoints = this.getMandatoryFolderCheckpointByStepByState(stepId, 'NON TRAITE');
        return untreatedCheckpoints.length;
    }

    public getImproperCount(stepId: string): number {
        const improperCheckpoints = this.getMandatoryFolderCheckpointByStepByState(stepId, 'NON CONFORME');
        return improperCheckpoints.length;
    }

    private getMandatoryFolderCheckpointByStepByState(stepId: string, stateName: string) {
        const folderCheckpoints = this.folder.folderCheckpoints
            .filter((folderCheckpoint: FolderCheckpoint) => folderCheckpoint.checkpoint.isMandatory)
            .filter((folderCheckpoint: FolderCheckpoint) => folderCheckpoint.checkpoint.step.id == stepId)
            .filter((folderCheckpoint: FolderCheckpoint) => folderCheckpoint.state.name == stateName);
        return folderCheckpoints;

    }

    public getfolderCheckpoint(stepId: string): FolderCheckpoint[] {
        const folderCheckpoints = this.folder.folderCheckpoints
            .filter((folderCheckpoint: FolderCheckpoint) => folderCheckpoint.checkpoint.step.id == stepId);
        return folderCheckpoints;
    }

    private showCommentSectionModal(folderCheckpoint: FolderCheckpoint) {
        this.layerService.show(
            CheckpointCommentComponent,
            {
                folderName: this.folder.name,
                folderCheckpoint: folderCheckpoint,
            },
            {
                size: LayerSize.Medium,
            },
        );
    }

    private updateStateCount(): void {
        this.folderService.setFolder(this.folder);
        this.folderService.setUntreatedCount();
        this.folderService.setCompliantCount();
        this.folderService.setImproperCount();
        this.folderService.setInapplicableCount();
    }

    public isCheckpointHidden(folderCheckpoint: FolderCheckpoint): boolean {
        const isMandatory = folderCheckpoint.checkpoint.isMandatory;
        const showMandatory = this.folderService.mandatory.active;
        const showOptional = this.folderService.optional.active;

        const isControlLevelShow = (isMandatory && showMandatory) || (!isMandatory && showOptional);

        const isUntreated = folderCheckpoint.state.name == 'NON TRAITE';
        if (isUntreated) {
            const showUntreated = this.folderService.untreated.active;
            return !(isControlLevelShow && showUntreated)
        }

        const isCompliant = folderCheckpoint.state.name == 'CONFORME';
        if (isCompliant) {
            const showCompliant = this.folderService.compliant.active;
            return !(isControlLevelShow && showCompliant)
        }

        const isImproper = folderCheckpoint.state.name == 'NON CONFORME';
        if (isImproper) {
            const showImproper = this.folderService.improper.active;
            return !(isControlLevelShow && showImproper)
        }

        const isInapplicable = folderCheckpoint.state.name == 'NON APPLICABLE';
        if (isInapplicable) {
            const showInapplicable = this.folderService.inapplicable.active;
            return !(isControlLevelShow && showInapplicable)
        }
        return false
    }
}