<div class="modal-header justify-content-center mb-4">
    <h5 class="modal-title text-orfee-red fw-bold">Commentaire du point de contrôle</h5>
</div>

<div class="modal-body justify-content-center mb-4" [formGroup]="formGroup">
    <p class="text-center">
        Renseignez un commentaire pour le projet 
        <strong>{{ folderName }}</strong>,
        au sujet du point de contrôle
        <strong>{{ code }}</strong>.
        <strong>
            En cas de programme impliquant des travaux de structure, un MOE disposant de la compétence structure est mandaté
        </strong>
        dont le statut est
        <strong>{{ state }}</strong>.
    </p>

    <ed-textarea 
        id="checkpoint-comment"
        placeholder="Commentaire"
        [maxTextSize]="1000"
        [remainingChars]="true"
        [formControlName]="'comment'">
    </ed-textarea>
</div>

<div class="container pt-2">
    <div class="row g-2 d-flex justify-content-center">
        <div class="col-sm-4">
            <ed-button class="btn-back" (click)="cancel()">
                Retour
            </ed-button>
        </div>
        <div class="col-sm-4">
            <ed-button class="btn-red" (click)="saveComment()" [disabled]="!checkCommentValidity()">
                Enregistrer le commentaire
            </ed-button>
        </div>
    </div>
</div>
