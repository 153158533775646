import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard, notAuthGuard, adminAuthGuard } from './core/guards/auth.guard';
import { HomeComponent } from './main/controllers/home/home.component';
import { MainModule } from './main/main.module';
import { MainComponent } from './main/controllers/main/main.component';
import { FolderComponent } from './main/controllers/folder/folder.component';

const routes: Routes = [
    { 
        path: '', 
        component: HomeComponent,
        canActivate: [notAuthGuard],
        children: [
            { path: '', component: FolderComponent },
        ],
    },

    {
        path: 'folder',
        component: FolderComponent,
        canActivate: [notAuthGuard],
        children: [
            { path: '', component: FolderComponent },
        ],
    },
    
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
