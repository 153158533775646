import { Inject, Injectable } from '@angular/core';

import { FolderCheckpoint } from '../models/folder.model';
import { RestService } from './rest.service';


@Injectable({
    providedIn: 'root',
})
export class FolderCheckpointService {
    private url: string = `/folder-checkpoint/`;

    constructor(@Inject(RestService) private restService: RestService) { }

    public updateFolderCheckpointState(folderCheckpoint: FolderCheckpoint) {
        const body = {
            id: folderCheckpoint.id,
            state: folderCheckpoint.state
        }
        return this.restService.patch(this.url, {}, body);
    }

    public updateFolderCheckpointComment(folderCheckpoint: FolderCheckpoint) {
        const body = {
            id: folderCheckpoint.id,
            comment: folderCheckpoint.comment
        }
        return this.restService.patch(this.url, {}, body);
    }
}
