import { AbstractControl, FormGroup, ValidatorFn } from '@angular/forms';

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const passwordRegex = /^(?=.*\d)(?=.*[A-Z])(?=.*[!/,;]).{8,}$/;

export function checkEmail(email: string): boolean {
  return emailRegex.test(String(email).toLowerCase());
}

export function checkPassword(password: string): boolean {
  return passwordRegex.test(password);
}

export function spreadErrorField(errorReturn: any, formGroup: FormGroup): void {
  if (!errorReturn?.error?.content?.field || !errorReturn.error.content.fieldError) {
    return;
  }

  const parts = errorReturn.error.content.field.split('.');

  let field = formGroup.get(parts[0]) as any;

  for (let i = 1; i < parts.length; i++) {
    field = field?.get(parts[i]);
  }

  field?.setErrors({ customError: errorReturn.error.content.fieldError });
  field?.markAsDirty();
}

export function markAllChildrenAsDirty(formGroup: FormGroup | undefined | null): void {
  if (!formGroup) {
    return;
  }

  Object.keys(formGroup.controls).forEach((controlName) => formGroup.get(controlName)?.markAsDirty());
}

export function getFormControl(
  rootFormGroup: FormGroup | null | undefined,
  ...args: string[]
): AbstractControl | undefined {
  if (args.length < 1 || !rootFormGroup) {
    return;
  }

  let formControl: AbstractControl = rootFormGroup;

  for (const arg of args) {
    const tmpControl: AbstractControl | null = formControl.get(arg);

    if (!tmpControl) {
      return;
    }

    formControl = tmpControl;
  }

  return formControl;
}

export function clearValidators(rootFormGroup: FormGroup | null | undefined, ...args: string[]): void {
  const formControl = getFormControl(rootFormGroup, ...args);

  formControl?.clearValidators();
  formControl?.updateValueAndValidity();
}

export function addValidators(
  validators: ValidatorFn | ValidatorFn[],
  rootFormGroup: FormGroup | null | undefined,
  ...args: string[]
): void {
  const formControl = getFormControl(rootFormGroup, ...args);

  formControl?.addValidators(validators);
  formControl?.updateValueAndValidity();
}

export function deleteError(errorKey: string, rootFormGroup: FormGroup | null | undefined, ...args: string[]): void {
  const formControl = getFormControl(rootFormGroup, ...args);

  delete formControl?.errors?.[errorKey];
  formControl?.updateValueAndValidity();
}
