import { Component, Inject } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertService, LayerService } from '@ed---interne/ng-uui-components';
import { Folder } from 'src/app/core/models/folder.model';

import { FolderService } from 'src/app/core/services/folder.service';

@Component({
    selector: 'delete-folder',
    templateUrl: './delete-folder.component.html',
    styleUrls: ['./delete-folder.component.scss'],
    providers: [FormGroupDirective]
})
export class DeleteFolderComponent {
    public folder: Folder | any = {};

    public set params(params: { folder: Folder; }) {
        this.folder = params.folder;
    }

    constructor(
        @Inject(FolderService) private folderService: FolderService,
        private readonly alertService: AlertService,
        private readonly layerService: LayerService,
        private readonly router: Router,
    ) { }

    public async deleteFolder(): Promise<void> {
        try {
            await this.folderService.delete();
            this.alertService.valid('Le dossier a été supprimé.', `Vous allez être redirigé vers la page d'accueil.`);
            setTimeout(() => this.router.navigate(['/']), 2000);
            this.layerService.close();
        } catch (e) {
            this.alertService.error('', 'Le numéro de dossier n\'existe pas ou n\'est pas associé à cette adresse mail.');
        }
    }

    public cancel(): void {
        this.layerService.close();
    }
}