<header>
<div class="container">
    <div class="row d-flex justify-content-center p-1">
        <div class="text-center">
                <img class="" src="../assets/logo_serafin.png" alt="logo">
        </div>
    </div>

    <div class="row align-items-center p-1">
        <div class="col d-flex justify-content-center">
            <h2 class="text-center"> {{ projectName }}</h2>
        </div>
    </div>
</div>
</header>
  