import { Component, Inject, OnInit } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from '@ed---interne/ng-uui-components';
import { Folder } from 'src/app/core/models/folder.model';
import { FolderService } from 'src/app/core/services/folder.service';
import { StepService } from 'src/app/core/services/step.service';

@Component({
  selector: 'app-folder',
  templateUrl: './folder.component.html',
  styleUrls: ['./folder.component.scss'],
  providers: [FormGroupDirective]
})
export class FolderComponent implements OnInit {
  email: string | null = null;
  reference: string | null = null;

  name: string = '';
  stepName: string = '';
  folder: Folder | null = null;

  constructor(
    @Inject(FolderService) private folderService: FolderService,
    private route: ActivatedRoute,
    private readonly router: Router,
    private readonly alertService: AlertService,
    private readonly stepService: StepService,
  ) { }

  async ngOnInit(): Promise<void> {
    this.route.queryParams.subscribe(params => {
      this.reference = params['reference'] || null;
      const encodedEmail = params['email'] || null;
      this.email = decodeURIComponent(encodedEmail);
    });

    try {
      const folder = await this.folderService.findByReferenceEmail(this.reference as string, this.email as string);
      this.folder = folder;
    } catch (e) {
      this.router.navigate(['/']);
      this.alertService.error('', 'Le numéro de dossier n\'existe pas ou n\'est pas associé à cette adresse mail');
    }
  }
}
