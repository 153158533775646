import { Component, Inject, OnInit, forwardRef } from '@angular/core';
import { FormBuilder, FormGroupDirective, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertService } from '@ed---interne/ng-uui-components';
import { Folder } from 'src/app/core/models/folder.model';
import { HouseType } from 'src/app/core/models/house-type';
import { FolderService } from 'src/app/core/services/folder.service';
import { HouseTypeUuid } from 'src/app/shared/enums/type.enum';
import { emailValidator } from '../../../shared/validators/email.validator';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    providers: [
        FormGroupDirective,
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => HomeComponent),
            multi: true,
        },
    ],
})
export class HomeComponent implements OnInit {
    public displayError = false;
    public createFolderProgress = false;

    public houseTypeOptions: HouseType[] = [
        { value: HouseTypeUuid.IndividualHousing, label: 'Maison individuelle' },
        { value: HouseTypeUuid.CollectiveHousing, label: 'Copropriété' },
    ];

    public loginFormGroup = this.formBuilder.group({
        email: ['', [emailValidator()]],
    });

    public createFormGroup = this.formBuilder.group({
        houseTypeId: ['', Validators.required],
        name: ['', Validators.required],
    });

    public referenceFormGroup = this.formBuilder.group({
        reference: ['', Validators.required],
    });

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly alertService: AlertService,
        private readonly router: Router,
        @Inject(FolderService) private folderService: FolderService,
    ) {}

    public ngOnInit(): void {
        this.createFormGroup.controls.houseTypeId.setValue(this.houseTypeOptions[0].value);
    }

    public checkMailValidity(): boolean {
        const emailCtrl = this.loginFormGroup.get('email');
        return !!emailCtrl?.value && emailCtrl?.valid;
    }

    public async createFolder(): Promise<void> {
        this.createFolderProgress = true;

        const emailCtrl = this.loginFormGroup.get('email');
        const email = emailCtrl?.value || null;

        const nameCtrl = this.createFormGroup.get('name');
        const name = nameCtrl?.value || null;

        const typeHouseCtrl = this.createFormGroup.get('houseTypeId');
        const houseTypeId = typeHouseCtrl?.value || undefined;

        const newFolder = {
            email: email,
            name: name,
            houseTypeId: houseTypeId,
        };

        try {
            const folder: Folder = await this.folderService.create(newFolder as Folder);
            const email = folder.email;
            const reference = folder.reference;
            this.alertService.valid('', 'Le dossier a été bien créé.');
            const encodedEmail = encodeURIComponent(email);
            this.router.navigate(['folder'], {
                queryParams: { email: encodedEmail, reference: reference },
            });
        } catch {
            this.alertService.error('', "La création de dossier n'a pas abouti.");
        } finally {
            this.createFolderProgress = false;
        }
    }

    public async openFolder(): Promise<void> {
        const emailCtrl = this.loginFormGroup.get('email');
        const email = emailCtrl?.value || null;

        const referenceCtrl = this.referenceFormGroup.get('reference');
        const reference = referenceCtrl?.value || null;

        try {
            const folder: Folder = await this.folderService.findByReferenceEmail(
                reference as string,
                email as string,
            );
            const encodedEmail = encodeURIComponent(folder.email);
            this.router.navigate(['folder'], {
                queryParams: { email: encodedEmail, reference: reference },
            });
        } catch (e) {
            this.alertService.error(
                '',
                "Le numéro de dossier n'existe pas ou n'est pas associé à cette adresse mail.",
            );
        }
    }

    public async sendFoldersLinkByEmail(): Promise<void> {
        try {
            await this.folderService.sendFoldersLinkByEmail(
                this.loginFormGroup.value.email as string,
            );
            this.alertService.valid('', "Un mail a été envoyé à l'adresse indiquée.");
        } catch (e) {
            this.alertService.error('Un problème est survenu.', "Le mail n'a pas été envoyé.");
        }
    }

    public disableOpenFolder(): boolean {
        const referenceCtrl = this.referenceFormGroup.get('reference');
        const reference = referenceCtrl?.value || null;
        return !reference || reference.length < 5;
    }

    public disableCreateFolder(): boolean {
        const nameCtrl = this.createFormGroup.get('name');
        const name = nameCtrl?.value || null;
        return !name;
    }
}
