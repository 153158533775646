
import { Inject, Injectable } from '@angular/core';
import { Step } from '../models/folder.model';
import { RestService } from './rest.service';


@Injectable({
    providedIn: 'root',
})
export class StepService {
    steps: Step[] = [];

    constructor(@Inject(RestService) private restService: RestService) { }

    public async getAvailableSteps(): Promise<Step[]> {
        if (this.steps.length) {
            return this.steps;
        }
        const url = '/steps';
        this.steps = await this.restService.get(url);
        return this.steps
    }
}    