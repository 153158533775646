import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { AlertService } from '@ed---interne/ng-uui-components';

import { Folder, FolderCheckpoint } from 'src/app/core/models/folder.model';
import { FolderService } from 'src/app/core/services/folder.service';

@Component({
    selector: 'folder-filters',
    templateUrl: './filters.component.html',
    styleUrls: ['./filters.component.scss'],
    providers: [FormGroupDirective]
})
export class FiltersComponent implements OnInit {
    @Input() folder: Folder | any;

    constructor(
        @Inject(FolderService) public folderService: FolderService,
        private readonly alertService: AlertService,
    ) { }

    ngOnInit(): void {
        this.folderService.mandatory.active = this.folder.showMandatory;
        this.folderService.optional.active = this.folder.showOptional;
        this.folderService.untreated.active = this.folder.showUntreated;
        this.folderService.compliant.active = this.folder.showCompliant;
        this.folderService.improper.active = this.folder.showImproper;
        this.folderService.inapplicable.active = this.folder.showInapplicable;

        this.setMandatoryOptionalCount();
        this.folderService.setFolder(this.folder);
        this.folderService.setUntreatedCount();
        this.folderService.setCompliantCount();
        this.folderService.setImproperCount();
        this.folderService.setInapplicableCount();
    }

    public async toggleMandatory(): Promise<void> {
        this.folderService.mandatory.active = !this.folderService.mandatory.active;
        this.folder.showMandatory = this.folderService.mandatory.active;
        this.folderService.setUntreatedCount();
        this.folderService.setCompliantCount();
        this.folderService.setImproperCount();
        this.folderService.setInapplicableCount();

        const data = { showMandatory: this.folder.showMandatory };
        await this.updateFilters(data);
    }

    public async toggleOptional(): Promise<void> {
        this.folderService.optional.active = !this.folderService.optional.active;
        this.folder.showOptional = this.folderService.optional.active;
        this.folderService.setUntreatedCount();
        this.folderService.setCompliantCount();
        this.folderService.setImproperCount();
        this.folderService.setInapplicableCount();

        const data = { showOptional: this.folder.showOptional };
        await this.updateFilters(data);
    }

    public async toggleUntreated(): Promise<void> {
        this.folderService.untreated.active = !this.folderService.untreated.active;
        this.folder.showUntreated = this.folderService.untreated.active;

        const data = { showUntreated: this.folder.showUntreated };
        await this.updateFilters(data);
    }

    public async toggleCompliant(): Promise<void> {
        this.folderService.compliant.active = !this.folderService.compliant.active;
        this.folder.showCompliant = this.folderService.compliant.active;

        const data = { showCompliant: this.folder.showCompliant };
        await this.updateFilters(data);
    }

    public async toggleImproper(): Promise<void> {
        this.folderService.improper.active = !this.folderService.improper.active;
        this.folder.showImproper = this.folderService.improper.active;

        const data = { showImproper: this.folder.showImproper };
        await this.updateFilters(data);
    }

    public async toggleInapplicable(): Promise<void> {
        this.folderService.inapplicable.active = !this.folderService.inapplicable.active;
        this.folder.showInapplicable = this.folderService.inapplicable.active;

        const data = { showInapplicable: this.folder.showInapplicable };
        await this.updateFilters(data);
    }

    private async updateFilters(data: Partial<Folder>): Promise<void> {
        try {
            await this.folderService.updateParams(data);
        } catch (e) {
            this.alertService.error('', 'Le numéro de dossier n\'existe pas ou n\'est pas associé à cette adresse mail.');
        }
    }

    private setMandatoryOptionalCount() {
        const mandatory = this.folder.folderCheckpoints.filter((folderCheckpoint: FolderCheckpoint) => folderCheckpoint.checkpoint.isMandatory);
        this.folderService.mandatory.total = mandatory.length;

        const optional = this.folder.folderCheckpoints.filter((folderCheckpoint: FolderCheckpoint) => !folderCheckpoint.checkpoint.isMandatory);
        this.folderService.optional.total = optional.length;
    }
}