<div class="folder-header">
    <div class="container folder-header">
        <div class="row h-100">
            <div class="col-2 d-flex align-items-center justify-content-start ps-0">
                <img class="logo" src="../assets/logo_serafin.png" alt="logo">
            </div>
            <div class="col-8 d-flex align-items-center justify-content-center title-header">
                Contrôle qualité du projet de rénovation 
            </div>
            <div class="col-2 d-flex align-items-center justify-content-end" title="Retourner sur la page d'accueil">
                <img (click)="backHome()" src="assets/svg/back.svg" class="logo-back">
            </div>
        </div>
    </div>
</div>